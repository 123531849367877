/* .learning-topics {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.topics-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.topic-card {
  background-color: #1e1e1e;
  color: #76ff03;
  border: 1px solid #333;
  border-radius: 10px;
  margin: 10px;
  padding: 20px;
  flex: 1 1 calc(25% - 40px);
  box-shadow: 0 0 10px #333;
  box-sizing: border-box;
  font-size: 14px;
  text-align: left;
}

.topic-card h2 {
  margin-top: 0;
  font-size: 20px;
}

.progress-bar {
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  height: 20px;
  margin-bottom: 10px;
}

.progress {
  background-color: #76ff03;
  height: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul li {
  background-color: #333;
  padding: 8px;
  margin: 5px 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
}

ul li:hover {
  background-color: #76ff03;
  color: #1e1e1e;
} */

/* Media query for smaller screens */
/* @media (max-width: 1200px) {
  .topic-card {
    flex: 1 1 calc(33.33% - 40px);
  }
}

@media (max-width: 768px) {
  .topic-card {
    flex: 1 1 calc(50% - 40px);
  }
}

@media (max-width: 480px) {
  .topic-card {
    flex: 1 1 calc(100% - 40px);
  }
} */

/* General container styles */
.container {
  padding: 20px;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
}

/* Divider after topic name */
.divider {
  border: 0;
  height: 1px;
  background: linear-gradient(to right, #ccc, #fff);
}

/* Card appearance */
.card {
  background-color: #f4f4f9;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #212529;
}

.text-muted {
  font-size: 0.9rem;
  color: #6c757d;
}

/* Progress bar */
.progress-bar {
  background-color: #ececec;
  border-radius: 8px;
  height: 6px;
  margin-bottom: 5px;
}

.progress {
  background: linear-gradient(90deg, #4caf50, #81c784);
  height: 100%;
  border-radius: 8px;
  transition: width 0.4s ease;
}

/* Sub-topic list */
ul {
  padding: 0;
}

.sub-topic {
  font-size: 0.95rem;
  padding: 6px 0;
  color: #000; /* Changed link color to black */
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
}

.sub-topic:hover {
  color: #333; /* Slight change on hover for visual feedback */
  transform: translateX(5px);
}

/* Cursor change for clickable elements */
.sub-topic {
  cursor: pointer;
}
