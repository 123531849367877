.visualization-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #121212;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
}
  
.variables-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
}
  
.variable-box {
    position: relative;
    background: linear-gradient(145deg, #444, #222);
    border: 2px solid #00e676;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 230, 118, 0.3), -5px -5px 10px rgba(0, 0, 0, 0.7);
    margin-right: 15px;
    padding: 10px;
    width: auto;
    min-width: 70px;
    height: 70px;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.variable-value {
    font-size: 18px;
    font-weight: bold;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.variable-name {
    font-size: 10px;
    border-top: 1px dotted #00e676;
    padding-top: 5px;
    margin-top: 5px;
}

.variable-name-highlighted {
    font-size: 10px;
    border-top: 1px dotted black;
    padding-top: 5px;
    margin-top: 5px;
}
  
.variable-address {
    font-size: 14px;
    background: linear-gradient(145deg, #00e676, #004d40); /* Gradient 3D effect */
    border-radius: 0 0 7px 7px;
    margin-top: 1px;
    margin-bottom: 20px;
    box-shadow: 2px 2px 1px rgba(0, 230, 118, 0.3), -2px -2px 1px rgba(0, 0, 0, 0.7);
    width: 80%;
    text-align: center;
}
  
.array-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.left-container-merge-sort {
    padding: 10px;
    /* border: 1px dotted green; */
}

.right-container-merge-sort {
    margin-left: 15px;
    padding: 10px;
    /* border: 1px dotted red; */
}
  
.array-variable-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(145deg, #444, #222);
    border: 2px solid #00e676;
    border-radius: 10px 0 0 10px;
    box-shadow: 5px 5px 10px rgba(0, 230, 118, 0.3), -5px -5px 10px rgba(0, 0, 0, 0.7);
    width: auto;
    height: 60px;
    text-align: center;
    color: #ffffff;
}

.array-variable-box-highlighted {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(145deg, #444, #222);
    border: 2px solid #ffeb3b;
    border-radius: 10px 0 0 10px;
    box-shadow: 5px 5px 10px #ff5722, -5px -5px 10px rgba(0, 0, 0, 0.7);
    width: auto;
    height: 60px;
    text-align: center;
    color: #ffffff;
}
  
.array-variable-name {
    font-size: 16px;
    font-weight: bold;
    padding: 5px;
}
  
.array-box {
    position: relative;
    background: linear-gradient(145deg, #444, #222);
    border-top: 2px solid #00e676;
    border-bottom: 2px solid #00e676;
    border-left: 2px solid #00e676;
    width: 50px;
    height: 60px;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.array-highlighted-box {
    position: relative;
    background: linear-gradient(145deg, #444, #222);
    border-top: 2px solid #ffeb3b;
    border-bottom: 2px solid #ffeb3b;
    border-left: 2px solid #ffeb3b;
    width: 50px;
    height: 60px;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
  
.array-box:first-child {
    border-left: none;
}
  
.array-box:last-child {
    border-right: 2px solid #00e676;
    border-radius: 0 10px 10px 0;
}

.array-highlighted-box:last-child {
    border-right: 2px solid #ffeb3b;
    border-radius: 0 10px 10px 0;
}
  
.array-index-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
  
.array-value {
    font-size: 18px;
    font-weight: bold;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    overflow: hidden;
}
  
.array-index {
    font-size: 14px;
    color: #bbbbbb;
    border-bottom: 1px dotted #00e676;
    width: 100%;
    text-align: center;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.highlighted {
    border: 2px solid #ff9800;
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
}

.highlighted-array {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
}

.highlighted-array-index {
    font-size: 11px;
    color: black;
    border-bottom: 1px dotted black;
    width: 100%;
    text-align: center;
    padding-bottom: 9px;
    margin-bottom: 5px;
}

.string-container-wrapper {
    display: block;
    justify-content: center;
    align-items: center;
}

.string-container {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    justify-content: center;
}

.string-variable-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(145deg, #444, #222);
    border: 2px solid #00e676;
    border-radius: 10px 0 0 10px;
    box-shadow: 5px 5px 10px rgba(0, 230, 118, 0.3), -5px -5px 10px rgba(0, 0, 0, 0.7);
    width: auto;
    height: 60px;
    text-align: center;
    color: #ffffff;
}

.string-variable-box-highlighted {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(145deg, #444, #222);
    border: 2px solid #ffeb3b;
    border-radius: 10px 0 0 10px;
    box-shadow: 5px 5px 10px #ff5722, -5px -5px 10px rgba(0, 0, 0, 0.7);
    width: auto;
    height: 60px;
    text-align: center;
    color: #ffffff;
}
  
.string-variable-name {
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
}
  
.string-box {
    position: relative;
    background: linear-gradient(145deg, #444, #222);
    border-top: 2px solid #00e676;
    border-bottom: 2px solid #00e676;
    border-left: 2px solid #00e676;
    width: 23px;
    height: 60px;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.string-highlighted-box {
    position: relative;
    background: linear-gradient(145deg, #444, #222);
    border-top: 2px solid #ffeb3b;
    border-bottom: 2px solid #ffeb3b;
    border-left: 2px solid #ffeb3b;
    width: 23px;
    min-width: 20px;
    height: 60px;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
  
.string-box:first-child {
    border-left: none;
}
  
.string-box:last-child {
    border-right: 2px solid #00e676;
    border-radius: 0 10px 10px 0;
}

.string-highlighted-box:last-child {
    border-right: 2px solid #ffeb3b;
    border-radius: 0 10px 10px 0;
}
  
.string-index-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
  
.string-value {
    font-size: 12px;
    font-weight: bold;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    overflow: hidden;
}
  
.string-index {
    font-size: 11px;
    color: #bbbbbb;
    border-bottom: 1px dotted #00e676;
    width: 100%;
    text-align: center;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.highlighted-string {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
}

.highlighted-str {
    border: 2px solid #ff9800;
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
}

.highlighted-string-index {
    font-size: 9px;
    color: black;
    border-bottom: 1px dotted black;
    width: 100%;
    text-align: center;
    padding-bottom: 9px;
    margin-bottom: 5px;
}

.highlighted-variable {
    border: 2px solid #ff9800;
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ff5722, -5px -5px 10px rgba(0, 0, 0, 0.7);
}

.variables-container, .main-array-container {
  margin-bottom: 20px;
}

.parent-child-container {
  position: relative;
  margin-bottom: 20px;
}

.parent-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.child-container {
  padding-left: 10px;
  position: relative;
}

.left-container-merge-sort,
.right-container-merge-sort {
  width: 50%;
}

.dictionary-wrapper {
    display: grid;
    grid-template-columns: repeat(2, max-content); /* Ensures exactly 2 containers per row */
    justify-content: center; /* Centers the containers in the wrapper */
    gap: 20px; /* Adds space between the containers */
}

.dictionary-container {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: 1px;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid #00e676;
    box-shadow: 5px 5px 10px #00e676, -5px -5px 10px #00e676;
    margin-bottom: 40px;
    color: white;
    width: fit-content;
}

.highlighted-dictionary-container {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: 1px; /* Small gap for 3D effect */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 5px 5px 10px #ffeb3b,
                -5px -5px 10px #ffeb3b;
    margin-bottom: 40px;
    width: fit-content;
}

.dictionary-row {
    background: linear-gradient(145deg, #444, #222);
    font-size: 11px;
    padding: 10px;
    width: auto;
}

.highlighted-dictionary-row {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    font-size: 11px;
    padding: 10px;
    width: auto;
    color: black;
}

.dictionary-cell {
    background: linear-gradient(145deg, #444, #222);
    font-size: 14px;
    padding: 10px;
}

.highlighted-dictionary-cell {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    font-size: 14px;
    padding: 10px;
}

.highlighted-dictionary-cell {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    font-size: 14px;
    padding: 10px;
    color: black;
}

.highlighted-dictionary-cell.merged,
.dictionary-cell.merged {
    grid-column: span 2;
    text-align: center;
}

.stack-container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stack-container {
    display: grid;
    grid-template-columns: 0fr 1fr;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid #00e676;
    box-shadow: 5px 5px 10px #00e676,
                -5px -5px 10px #00e676;
    margin-bottom: 40px;
    height: fit-content;
    color: white;
    width: fit-content;
}

.highlighted-stack-container {
    display: grid;
    grid-template-columns: 0fr 1fr;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 5px 5px 10px #ffeb3b,
                -5px -5px 10px #ffeb3b;
    margin-bottom: 40px;
    height: fit-content;
    width: fit-content;
}

.stack-top-row {
    background: linear-gradient(145deg, #444, #222);
    width: auto;
    white-space: nowrap;
    font-size: 9px;
    padding: 4px;
    padding-top: 10px;
    height: 26px;
}

.highlighted-stack-top-row {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    width: auto;
    color: black;
    white-space: nowrap;
    font-size: 9px;
    padding: 4px;
    padding-top: 10px;
    height: 26px;
}

.stack-row {
    background: linear-gradient(145deg, #444, #222);
    font-size: 11px;
    padding: 10px;
    width: auto;
    height: 35px;
}

.highlighted-stack-row {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    font-size: 11px;
    text-align: center;
    width: auto;
    color: black;
    height: 35px;
}

.stack-top-cell {
    background: linear-gradient(145deg, #444, #222);
    padding: 10px;
    min-width: 40px;
    text-align: center;
    font-size: 9px;
    height: 26px;
}

.highlighted-stack-top-cell {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    padding: 10px;
    color: black;
    min-width: 40px;
    text-align: center;
    font-size: 9px;
    height: 26px;
}

.stack-cell {
    background: linear-gradient(145deg, #444, #222);
    font-size: 14px;
    padding: 10px;
    height: 35px;
}

.highlighted-stack-cell {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    font-size: 14px;
    padding: 10px;
    color: black;
    height: 35px;
}

.highlighted-dictionary-cell {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    font-size: 14px;
    padding: 10px;
    color: black;
}

.class-diagram {
    width: auto;
    margin: 20px;
    border: 2px solid #00e676;
    box-shadow: 3px 3px 5px #00e676,
                -3px -3px 5px #00e676;
    color: white;
}

.highlighted-class-diagram {
    width: auto;
    margin: 20px;
    border: 2px solid #ffeb3b;
    box-shadow: 5px 5px 10px #ffeb3b,
                -5px -5px 10px #ffeb3b;
}

.class-name {
    background: linear-gradient(145deg, #444, #222);
    text-align: center;
    font-weight: bold;
    padding: 5px;
    border-bottom: 2px dashed #00e676;
}

.highlighted-class-name {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
    text-align: center;
    font-weight: bold;
    padding: 5px;
    border-bottom: 2px dashed black;
}

.attributes, .methods {
    background: linear-gradient(145deg, #444, #222);
    padding: 5px;
    border-bottom: 2px dashed #00e676;
}

.highlighted-attributes, .highlighted-methods {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
    padding: 5px;
    border-bottom: 2px dashed black;
}

.methods {
    border-bottom: none;
}

.object-name {
    background: linear-gradient(145deg, #444, #00e676);
    text-align: center;
    font-weight: bold;
    padding: 5px;
}

.highlighted-object-name {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
    text-align: center;
    font-weight: bold;
    padding: 5px;
}

.highlighted-class-method {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
}

.interface-name {
    background: linear-gradient(145deg, #ffeb3b, #00e676);
    text-align: center;
    font-weight: bold;
    padding: 5px;
    color: black;
}

.highlighted-interface-name {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
    text-align: center;
    font-weight: bold;
    padding: 5px;
}

.node-container {
    display: flex;
}

.node-block {
    padding-right: 40px;
}

.node {
    display: flex;
    width: 70px;
    height: 50px;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    border: 2px solid #00e676;
    box-shadow: 3px 3px 5px #00e676,
                -3px -3px 5px #00e676;
}

.highlighted-node {
    display: flex;
    width: 70px;
    height: 50px;
    /* margin: 20px; */
    font-size: 12px;
    font-weight: bold;
    position: relative;
    border: 2px solid #ffeb3b;
    box-shadow: 3px 3px 5px #ffeb3b,
                -3px -3px 5px #ffeb3b;
}

.node-data,
.node-pointer {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient(145deg, #444, #222);
    color: #ffffff;
}

.node-full {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient(145deg, #444, #222);
    color: #ffffff;
}

.highlighted-node-data,
.highlighted-node-pointer {
    width: 50%;
    height: 100%;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
}

.node-pointer {
    width: 50%;
    border-left: 1px solid #bbbbbb;
    font-size: 11px;
}

.node-address {
    font-size: 14px;
    background: linear-gradient(145deg, #00e676, #004d40);
    border-radius: 0 0 7px 7px;
    /* margin-top: -15px;
    margin-left: 22px; */
    /* margin-bottom: 20px; */
    box-shadow: 2px 2px 1px rgba(0, 230, 118, 0.3), -2px -2px 1px rgba(0, 0, 0, 0.7);
    /* width: 70px; */
    text-align: center;
}

.highlighted-node-address {
    font-size: 14px;
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    border-radius: 0 0 7px 7px;
    /* margin-top: -15px;
    margin-left: 22px; */
    /* margin-bottom: 20px; */
    box-shadow: 2px 2px 1px #ffeb3b, -2px -2px 1px #ffeb3b;
    /* width: 70px; */
    text-align: center;
    color: black;
}

.scrollable-content {
    height: 100px;
    overflow-x: hidden;
    overflow-y: scroll
}

.scrollable-content::-webkit-scrollbar {
    width: 8px;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background-color: rgb(118, 255, 3);
    background-color: #444;
    border-radius: 10px;
}


.tree-node {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 17px;
    margin-right: 17px;
    position: relative;
    color: white;
}

.node-box {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    background: linear-gradient(145deg, #444, #222);
    border: 2px solid #00e676;
    box-shadow: 3px 3px 5px #00e676,
                -3px -3px 5px #00e676;
    text-align: center;
    width: 95px;
    height: 35px;
}

.highlighted-node-box {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    border: 2px solid #ffeb3b;
    box-shadow: 3px 3px 5px #ffeb3b,
                -3px -3px 5px #ffeb3b;
    text-align: center;
    color: black;
    width: 95px;
    height: 35px;
}

.left-part,
.data-part,
.right-part {
    padding-top: 10px;
    width: 40px;
    font-size: 10px;
    font-weight: bold;
}

.highlighted-left-part,
.highlighted-data-part,
.highlighted-right-part {
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
    padding-top: 10px;
    width: 40px;
    font-size: 10px;
    font-weight: bold;
}

.data-part {
    padding-top: 6px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    font-weight: bold;
    font-size: 15px;
}

.highlighted-data-part {
    padding-top: 6px;
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    color: black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    font-weight: bold;
    font-size: 15px;
}

.child-nodes {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
}

.left-child,
.right-child {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tree-node-address {
    justify-content: space-between;
    text-align: center;
    background: linear-gradient(145deg, #00e676, #004d40);
    border-radius: 0 0 7px 7px;
    box-shadow: 2px 2px 1px rgba(0, 230, 118, 0.3), -2px -2px 1px #004d40;
}

.highlighted-tree-node-address {
    justify-content: space-between;
    text-align: center;
    color: black;
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    border-radius: 0 0 7px 7px;
    box-shadow: 3px 3px 5px #ffeb3b,
                -3px -3px 5px #ffeb3b;
}

.line-through {
    text-decoration: line-through;
    color: #ff5722;
}

.graph-container {
    position: relative;
    width: 500px;
    height: 400px;
}

.graph-node {
    position: absolute;
    width: 60px;
    height: 60px;
    /* background-color: #61dafb; */
    background: linear-gradient(145deg, #444, #222);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
    font-weight: bold;
    /* border: 2px solid white; */
    border: 2px solid #00e676;
    box-shadow: 0px 0px 10px #00e676,
                -0px -0px 10px #00e676;
    z-index: 1;
}

.highlighted-graph-node {
    position: absolute;
    width: 60px;
    height: 60px;
    /* background-color: #61dafb; */
    background: linear-gradient(145deg, #444, #222);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: black;
    background: linear-gradient(145deg, #ffeb3b, #ff5722);
    box-shadow: 0px 0px 10px #ffeb3b,
                -0px -0px 10px #ffeb3b;
    font-weight: bold;
    /* border: 2px solid #ff5722; */
    z-index: 1;
}

.graph-memory-address {
    font-size: 12px;
    margin-bottom: 5px;
}

.graph-value {
    font-size: 16px;
}

.graph-line {
    position: absolute;
    height: 2px;
    background-color: #00e676;
    z-index: 0;
    transform-origin: 0 50%;
}

.graph-line::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 12px solid #00e676;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
}

.highlighted-graph-line {
    position: absolute;
    height: 2px;
    background-color: #00e676;
    z-index: 0;
    transform-origin: 0 50%;
}

.highlighted-graph-line::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 17px solid #ff5722;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
}
