h1, h2, h3 {
  font-weight: bold;
}

/* Header */
header {
  padding: 1.5rem 0; /* Reduced padding */
}

header h1 {
  font-size: 2.5rem; /* Reduced font size */
}

/* Main Content */
main {
  padding: 2rem;
}

section h2 {
  font-size: 1.75rem; /* Reduced font size */
  margin-bottom: 1rem;
}

section p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.main-content {
  padding-left: 50px;
  padding-right: 50px
}

.video-wrapper {
  position: relative;
  max-width: 85%;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.video-wrapper:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease-in-out;
}

.play-overlay {
  transition: opacity 0.3s ease-in-out;
}

.video-wrapper:hover .play-overlay {
  opacity: 1;
}

.card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.card-header {
  font-size: 1.25rem;
}

.container {
  max-width: 1100px;
}

/* Footer */
footer {
  background-color: #343a40;
  color: #fff;
  padding: 1rem 0;
  margin-top: 2rem;
}

/* Editor and Visualization */
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  height: calc(100vh - 120px);
  padding: 1rem;
}

/* AI Code Challenge Editor Section */
.ai-code-challenge-editor-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  height: 99vh;
  display: flex;
  flex-direction: column;
}

/* Code Editor Section */
.editor-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#editor {
  width: 100%;
  height: 97%;
  padding: 0.8rem;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.7rem;
  line-height: 0.9rem;
  border-radius: 5px;
  border: 1px solid #ced4da;
  background-color: #f0f0f0;
  resize: none;
  position: relative;
  overflow-y: auto;
  scrollbar-width: thin; /* Works in Firefox */
  scrollbar-color: rgba(100, 100, 100, 0.5) transparent;
}

/* Chrome, Edge, Safari */
#editor::-webkit-scrollbar {
  width: 8px; /* Thin scrollbar */
}

#editor::-webkit-scrollbar-track {
  background: transparent; /* Invisible track */
}

#editor::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #555, #999); /* Gradient thumb */
  border-radius: 4px; /* Rounded edges */
}

#editor::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #444, #888);
}

/* Scrollbar for the editor */
.editor-scrollbar {
  flex: 1;
  overflow-y: auto;
}

pre code.hljs {
  display: block !important;
  overflow-x: inherit !important;
  padding: 0px !important;
}

code.hljs {
  padding: 0px !important;
}

.hljs {
  color: inherit !important;
  background: inherit !important;
}

/* Floating Copy Button Inside Pre */
.copy-btn {
  top: 10px;
  right: 10px;
  padding: 0.25rem 0.75rem;
  z-index: 1;
  position: absolute;
  background-color: #343a40;
  color: #fff;
  opacity: 0.8;
  font-size: 11px;
  width: auto;
  padding: 5px;
  font-weight: bold;
}

.zoom-btn {
  position: absolute;
  border: none;
  cursor: pointer;
  background: none;
}

.circle-button {
  position: relative;
  width: 16px;
  height: 16px;
  background-color: #f0f0f0;
  border: 2px solid #343a40;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.circle-button:hover {
  transform: scale(1.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.plus-circle::before,
.plus-circle::after,
.minus-circle::before {
  content: '';
  position: absolute;
  background-color: #343a40;
  transition: background-color 0.3s ease;
}

.circle-button:hover .plus-circle::before,
.circle-button:hover .plus-circle::after,
.circle-button:hover .minus-circle::before {
  background-color: #f0f0f0;
}

.plus-circle::before {
  width: 60%;
  height: 2px;
}

.plus-circle::after {
  width: 2px;
  height: 60%;
}

.minus-circle::before {
  width: 60%;
  height: 2px;
}

.pre-div {
  display: grid !important;
  grid-template-columns: 40px 1fr; /* 40px for line number, the rest for code */
  align-items: start;
}

.line-number {
  width: 15px;
  text-align: right;
  color: #888;
  cursor: pointer;
  user-select: none; /* Disable selecting line numbers */
}

.code-content {
  word-break: break-word;
  padding-left: 12px;
}

/* Container for line numbers and breakpoints */
.line-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  flex-shrink: 0;
}

/* Placeholder for breakpoint icons */
.breakpoint-placeholder {
  width: 7px;
  height: 7px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breakpoint-placeholder.show-icon .breakpoint-icon {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--bs-dark);
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
}

.breakpoint-placeholder.show-icon .breakpoint-icon.active {
  background-color: var(--bs-dark);
  box-shadow: 0 0 8px rgba(32, 31, 31, 0.6);
}

.breakpoint-placeholder.show-icon .breakpoint-icon:hover {
  transform: scale(1.2);
  background-color: var(--bs-dark);
}

/* Visualize Section */
.visualize-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.logger-drawer {
  width: 100%;
  max-width: 100%;
  height: 150px;
  bottom: -150px;
  left: 0;
  transition: bottom 0.3s ease;
  background-color: rgba(0, 0, 0, 0.8);
  /* background-color: rgba(33, 37, 41, 0.9); */
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  /* cursor: move; */
  z-index: 1000;
}

.logger-drawer-closed {
  width: 100%;
  max-width: 100%;
  height: 20px;
  bottom: -20px;
  left: 0;
  transition: bottom 0.3s ease;
  background-color: rgba(0, 0, 0, 0.8);
  /* background-color: rgba(33, 37, 41, 0.9); */
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  /* cursor: move; */
  z-index: 1000;
  margin-bottom: 10px;
}

.logger-drawer.open {
  bottom: 0;
}

.toggle-icon {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  /* background-color: rgba(33, 37, 41, 0.9); */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.toggle-icon i {
  font-size: 1.2rem;
  color: white;
}

/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');

.logger-content {
  padding: 1rem;
  font-size: 0.9rem;
  max-height: 100%;
  overflow-y: auto;
  padding-right: 10px;
  /* font-family: 'IBM Plex Mono', sans-serif; */
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #16a085; /* exotic green color */
  /*background-color: #212529; /* dark background for contrast */
  letter-spacing: 0.05em;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* subtle shadow for depth */
  border-radius: 8px;
}

/* Scrollbar Styling */
.logger-content::-webkit-scrollbar {
  width: 8px;
}

.logger-content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.logger-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0);
}

.logger-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* Firefox scrollbar styling */
.logger-content {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1);
}

.visualization-canvas, .logger {
  height: 100%;
  padding: 1rem;
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  border-radius: 5px;
}

/* Get Started Button Styling */
.get-started-btn {
  background-color: #343a40;
  color: #ffffff;
  padding: 0.50rem 1.7rem;
  font-size: 1.25rem; /* Adjusted for consistency */
  font-weight: bold;
  border-radius: 50px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
}

.get-started-btn:hover {
  background-color: #495057;
  color: #ffffff;
  transform: scale(1.1);
}

.get-started-btn:active {
  transform: scale(1.05);
}

.get-started-btn:focus {
  outline: none;
}

/*************************************************/
.main-container {
  padding: 2rem;
}

.content-area {
  display: flex;
  gap: 2rem;
}

.section-title {
  font-weight: bold;
  color: #333;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

/* Visualize Section */
.visualize-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  height: 100%;
}

.visualization-canvas {
  border: 2px dashed #ccc;
  flex-grow: 1;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
}

/* Visualization Controls */
.visualization-controls {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for small screens */
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
}

.visualization-controls .btn {
  flex: 1 1 auto;
  /* margin: 0.5rem; */
  padding: 0.5rem 1rem;
}

.visualization-controls .form-range {
  width: 100%;
  max-width: 150px;
  /* margin: 0.5rem 0; */
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background-color: #343a40; /* Matches theme color */
  cursor: pointer;
  border-radius: 50%;
}

.form-range::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #343a40;
  cursor: pointer;
  border-radius: 50%;
}

/* Logger Section */
.logger-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  height: 250px;
  overflow-y: auto;
}

.logger {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #ced4da;
  height: 100%;
}

/* Custom Scrollbars */
.logger::-webkit-scrollbar {
  width: 8px;
}

.logger::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.logger::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Completed Button in Top-Right Corner */
.complete-btn {
  background-color: #28a745;
  color: #ffffff;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.complete-btn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.complete-btn:active {
  transform: scale(1.02);
}

.complete-btn:focus {
  outline: none;
}


/* Algorithm Description Section */
.description-card {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 1.5rem;
}

#algorithmDescription, #realLifeUse, #timeComplexity {
    font-size: 0.9rem;
    line-height: 1.6;
    padding-left: 25px;
}

.highlight {
  background-color: #00e676;
  color: #000 !important;
  box-shadow: 0 0 10px #00e676;
}

.calling-highlight {
  background: linear-gradient(145deg, #fcdc84, #ff5722);
  color: #000 !important;
  box-shadow: 0 0 10px #ff9800;
}

/* General button styles */
.toggle-btn {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
}

/* Toggle switch container */
.toggle-switch {
  width: 50px;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 24px;
  position: relative;
  transition: background-color 0.3s ease;
}

/* Toggle knob */
.toggle-knob {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.3s ease;
}

.btn-toggle-on .toggle-switch {
  background-color: #198754; /* Modern green shade */
}

.btn-toggle-off .toggle-switch {
  background-color: #9e9e9e; /* Neutral gray shade */
}

.btn-toggle-on .toggle-knob {
  left: calc(100% - 24px); /* Positioned at the far end */
}

.btn-toggle-off .toggle-knob {
  left: 4px; /* Positioned at the start */
}

/* Toggle label */
.toggle-label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.btn-toggle-on .toggle-label {
  color: #198754;
}

.btn-toggle-off .toggle-label {
  color: #9e9e9e;
}


.editor-theme-toggle-btn-on .toggle-switch {
  background-color: #212529; /* Modern green shade */
}

.editor-theme-toggle-btn-off .toggle-switch {
  background-color: #f0f0f0; /* Neutral gray shade */
}

.editor-theme-toggle-btn-on .toggle-knob {
  left: calc(100% - 24px); /* Positioned at the far end */
}

.editor-theme-toggle-btn-off .toggle-knob {
  left: 4px; /* Positioned at the start */
}


/* Mute/Unmute Button Styling */
.mute-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 30%, rgba(0,0,0,0) 70%);
}

.mute-button.unmuted {
  /* border: 2px solid #1DB954;
  box-shadow: 0px 0px 15px 2px #1DB954; */
  border: 2px solid #198754;
  box-shadow: 0px 0px 15px 2px #198754;
}

.mute-button.muted {
  /* border: 2px solid #f54291;
  box-shadow: 0px 0px 15px 2px #f54291; */
  border: 2px solid #ffc107;
  box-shadow: 0px 0px 15px 2px #ffc107;
}

.mute-button .inner-circle {
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.mute-button .inner-circle .wave {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  border-radius: 50%;
  animation: wave 1.5s infinite;
}

.mute-button.muted .inner-circle .wave {
  display: none; /* No wave animation when muted */
}

/* Wave Animation */
@keyframes wave {
  0% {
      transform: scale(0.7);
      opacity: 0.7;
  }
  50% {
      transform: scale(1.2);
      opacity: 0.3;
  }
  100% {
      transform: scale(1.5);
      opacity: 0;
  }
}

/* Pure Glassmorphism Effect */
.modal-glass {
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent black for full glass effect */
  backdrop-filter: blur(20px); /* Strong blur for glassy appearance */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Light border for subtle edge */
  border-radius: 15px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.6); /* Shadow for depth */
  padding: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #e0e0e0; /* Light text color for readability */
}

.modal-glass-avatar {
  background: rgba(238, 236, 236, 0.6); /* Semi-transparent black for full glass effect */
  backdrop-filter: blur(20px); /* Strong blur for glassy appearance */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Light border for subtle edge */
  border-radius: 15px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.6); /* Shadow for depth */
  padding: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #0a0a0a !important; /* Light text color for readability */
}

/* Modal Header */
.modal-header {
  background: none; /* Transparent background */
  border-bottom: none;
  text-align: center;
}

.modal-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffffff; /* White title for strong contrast */
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.modal-title-avatar {
  font-size: 1.5rem;
  font-weight: bold;
  color: #020202; /* White title for strong contrast */
  text-shadow: 0px 2px 5px rgba(238, 232, 232, 0.5);
}

.modal-title-update-variable {
  font-size: 1.3rem;
  font-weight: bold;
  color: #020202;
  text-shadow: 0px 2px 5px rgba(238, 232, 232, 0.5);
}

/* Transparent Input Fields with Subtle Borders */
.form-control {
  background: rgba(255, 255, 255, 0.1); /* Very light transparency for glass effect */
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border */
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.form-label {
  font-size: 14px;
  font-weight: bold;
}

/* Glass-Themed Button */
.btn-glass {
  font-weight: bold;
  border-radius: 8px;
  padding: 0.75rem;
  transition: all 0.3s ease;
  border-color: rgba(255, 255, 255, 0.1);
}

/* Footer Link */
.modal-footer a {
  transition: color 0.3s ease;
}

.modal-footer a:hover {
  color: #ffffff;
}

/* OTP Input Fields */
.otp-inputs {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.otp-input {
  width: 2.5rem;
  padding: 0.1rem;
  font-size: 1.5rem;
  text-align: center;
  border: none;
  border-bottom: 2px solid black;
  transition: border-color 0.3s ease, transform 0.3s ease;
  background-color: transparent;
  color: black;
}

.otp-input:focus {
  outline: none;
  transform: scale(1.1);
}

.phone-input-container {
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.phone-input-container:focus-within {
  border-color: rgba(238, 234, 234, 0.1);
  box-shadow: 0 0 0 0.2rem rgba(247, 244, 244, 0.1);
}

.country-code {
  border: none;
  padding: 13px;
  background: rgba(0, 0, 0, 0.4);
  font-size: 1rem;
  color: white;
  border-right: 1px solid #ced4da;
  outline: none;
  cursor: pointer;
  appearance: none;
}

.country-code option {
  background: rgba(0, 0, 0, 0.4);
  color: white;
}

.custom-input {
  border: none;
  flex: 1;
  padding: 15px;
  font-size: 14px;
  color: white;
  outline: none;
  background: rgba(0, 0, 0, 0.4);
}

.custom-input::placeholder {
  color: #b0b0b0;
  opacity: 0.6;
}

.custom-input:focus-within {
  color: white;
  background: rgba(0, 0, 0, 0.4);
  border-color: rgba(238, 234, 234, 0.1);
  box-shadow: 0 0 0 0.2rem rgba(247, 244, 244, 0.1);
}

.login-custom-input {
  border: none;
  flex: 1;
  padding: 15px;
  font-size: 14px;
  background: white;
  border: 1px solid #ccc;
  color: black;
}

.disabled-input-box {
  background-color: #ced4da !important;
  color: black
}

.btn-close {
  position: relative;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
}

.animated-modal-close-button {
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  background-color: transparent;
}

.animated-modal-close-button:hover {
  transform: scale(1.2);
}

.animated-modal-close-button:active {
  transform: scale(0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.btn-close:active {
  border: 2px solid #aaa;
}

.btn-close {
  filter: brightness(0) invert(1);
  opacity: 0.8;
}


/* Apply custom styles to the autofill state */
input:-webkit-autofill {
  background-color: inherit !important;
  color: inherit !important;
  border: 1px solid #ccc;
  box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* For Firefox (if applicable) */
input:autofill {
  background-color: inherit !important;
  color: inherit !important;
}

.modal-footer .anchor-tag:hover {
  color: black !important;
}


.animated-response {
  border-radius: 8px;
  color: #1a73e8;
  font-weight: 500;
  margin-top: 15px;
  animation: fadeInBounce 0.6s ease-in-out;
}

.opacity-95 {
  opacity: 0.95;
}

/* Animation effect */
@keyframes fadeInBounce {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.testimonial footer {
  color: white;
}

.email-verification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.custom-dropdown {
  appearance: none;
  background-color: #1e1e1e;
  color: #f0f0f0;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 10px 15px;
  width: 100%;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-dropdown:hover, 
.custom-dropdown:focus {
  background-color: #292929;
  border-color: #555;
}

.custom-dropdown option {
  background-color: #1e1e1e;
  color: #f0f0f0;
  padding: 10px;
}

.custom-dropdown::-webkit-scrollbar {
  width: 8px;
}

.custom-dropdown::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

.custom-dropdown::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

.custom-dropdown-profile {
  appearance: none;
  border-radius: 8px;
  padding: 13px 15px;
  width: 100%;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-dropdown-profile::-webkit-scrollbar {
  width: 8px;
}

.custom-dropdown-profile::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

.custom-dropdown-profile::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

.profile-avatar-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.profile-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
}

.avatar-in-modal {
  cursor: pointer;
}

.avatar-in-modal:hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  transform: scale(1.3);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
}

.avatar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(0, 0, 0, 0.6) 100%
  ); /* Smooth gradient overlay */
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.edit-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8); /* Slightly smaller on load */
  font-size: 1rem;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.profile-avatar-wrapper:hover .avatar-overlay,
.profile-avatar-wrapper:hover .edit-icon {
  opacity: 1;
}

.profile-avatar-wrapper:hover .edit-icon {
  transform: translate(-50%, -50%) scale(1); /* Smooth zoom on icon */
}

.profile-avatar::after {
  content: "Change Avatar";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.profile-avatar-wrapper:hover .profile-avatar::after {
  opacity: 1;
}

/* Modern dark dropdown menu styling */
.dropdown-dark {
  background-color: #212529; /* Matches navbar color */
  border: none;
  border-radius: 8px; /* Smooth rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  overflow: hidden; /* To keep hover effects within rounded corners */
  transition: all 0.3s ease; /* Smooth animation for hover/active effects */
}

/* Menu item styling */
.dropdown-dark .dropdown-item {
  color: #ffffff;
  padding: 10px 15px; /* Spacing for a cleaner, more comfortable look */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover and focus effects */
.dropdown-dark .dropdown-item:hover,
.dropdown-dark .dropdown-item:focus {
  background-color: #343a40; /* Slightly lighter on hover */
  color: #ffffff;
  transform: translateX(5px); /* Slide effect on hover */
}

/* Active effect */
.dropdown-dark .dropdown-item:active {
  background-color: #495057; /* Darker shade for active state */
  color: #ffffff;
  transform: translateX(0); /* Reset any transform on active click */
}

/* Optional: Divider styling */
.dropdown-dark .dropdown-divider {
  border-color: rgba(255, 255, 255, 0.15); /* Light divider for better contrast */
}

.user-progress-animation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-progress-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 44px;
  font-weight: bold;
  margin-bottom: 10px;
  animation: user-progress-scaleUp 0.5s ease-in-out;
}

.user-progress-success .user-progress-circle {
  background-color: #28a745;
}

.user-progress-failure .user-progress-circle {
  background-color: #dc3545;
}

.user-progress-tick-mark {
  color: white;
}

.user-progress-cross-mark {
  color: white;
}

.user-progress-fail-message {
  color: rgb(224, 23, 23);
  font-weight: bold;
}

.user-progress-success-message {
  color: rgb(7, 161, 32);
  font-weight: bold;
}

@keyframes user-progress-scaleUp {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}


.user-progress-close-btn {
  background-color: #495057;
  color: #ffffff;
  padding: 0.20rem 1.0rem;
  font-size: 1.0rem; /* Adjusted for consistency */
  font-weight: bold;
  border-radius: 20px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
}

.user-progress-close-btn:hover {
  background-color: #495057;
  color: #ffffff;
  transform: scale(1.1);
}

.user-progress-close-btn:active {
  transform: scale(1.05);
}

.user-progress-close-btn:focus {
  outline: none;
}

.learning-topics {
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.modern-btn {
  background: linear-gradient(135deg, #343a40, #4e555c);
  color: white;
  font-size: 15px;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  cursor: pointer;
}

.modern-btn:hover {
  background: linear-gradient(135deg, #343a40, #4e555c);
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2), 0 3px 5px rgba(0, 0, 0, 0.15);
}

.modern-btn:active {
  transform: scale(0.95);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.modern-btn:disabled {
  cursor: not-allowed;
  opacity: 0.9;
  transform: none;
}

.delay-btn {
  cursor: grabbing;
}

.delay-btn:disabled {
  cursor: not-allowed;
}

.btn-loading {
  background: linear-gradient(90deg, #212529, #495057);
  cursor: not-allowed;
  animation: loading 1.5s infinite;
  transition: all 0.3s ease;
}

.btn-verified {
  color: #fff;
  transition: all 0.3s ease;
}

.subscribe-link .badge {
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.subscribe-link .badge:hover {
  background-color: #343a40;
  /* transform: scale(1.1); */
  box-shadow: 5px 4px 8px rgba(0, 0, 0, 0.2);
}

.dot-ellipsis span {
  animation: blink 1.4s infinite;
  font-weight: bold;
  font-size: 16px;
}

.dot-ellipsis span:nth-child(1) {
  animation-delay: 0s;
}
.dot-ellipsis span:nth-child(2) {
  animation-delay: 0.2s;
}
.dot-ellipsis span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}


.testimonial-section {
  background: linear-gradient(to right, #f8f9fa, #e9ecef);
  color: #212529;
}

.testimonial-card {
  max-width: 600px;
  border: none;
  border-radius: 10px;
  background: #fff;
}

.testimonial-avatar img {
  width: 100px;
  height: 100px;
  border: 3px solid #6c757d;
}

.testimonial-quote {
  font-size: 1.25rem;
  font-style: italic;
  line-height: 1.6;
  color: #495057;
}

.blockquote-footer {
  font-size: 14px;
  color: #F2F0EF;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1);
}

.carousel-indicators {
  position: relative;
}

.carousel-indicators .dot {
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border-radius: 50%;
  background-color: #d6d6d6;
  border: none;
  transition: background-color 0.3s ease;
}

.carousel-indicators .dot.active {
  background-color: #6c757d;
}


.floating-drawer {
  position: fixed;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  /* background: linear-gradient(145deg, #ffffff, #e6e6e6); */
  background: #e6e6e6;
  /* border: 1px solid rgba(255, 255, 255, 0.3); */
  border-radius: 20px 0 0 20px;
  padding: 5px;
  /* box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2), 0 4px 15px rgba(0, 0, 0, 0.1) inset; */
  backdrop-filter: blur(10px);
  z-index: 1000;
  transition: width 0.3s ease, height 0.3s ease, padding 0.3s ease;
}

.floating-drawer.closed {
  width: 0px;
  height: 0px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0;
}

.floating-drawer.open {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.toggle-button {
  position: absolute;
  left: -32px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  /* background: linear-gradient(145deg, #ffffff, #e6e6e6); */
  background: #e6e6e6;
  color: rgb(131, 128, 128);
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  /* box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3); */
  display: flex;
  justify-content: right;
  align-items: center;
  clip-path: circle(50% at 75% 50%); /* Left side is circular, right side is cut */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.drawer-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  cursor: pointer;
  transition: transform 0.4s ease, box-shadow 0.3s ease;
}

.drawer-icon img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  filter: drop-shadow(0 3px 5px rgba(148, 147, 147, 0.2));
  transition: transform 0.4s ease, filter 0.3s ease;
}

.drawer-icon:hover img {
  transform: scale(1.2);
  /* filter: drop-shadow(0 8px 15px rgba(62, 63, 62, 0.8)); */
}

.tooltip {
  position: absolute;
  bottom: -30px;
  background-color: #212529;
  color: white;
  padding: 6px 10px;
  border-radius: 8px;
  font-size: 12px;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
  white-space: nowrap;
}

.drawer-icon:hover .tooltip {
  opacity: 1;
  transform: translateY(0);
}

.padding-25px {
  padding: 25px;
}

.language-dropdown-menu {
  background-color: #f8f9fa !important;
}

.language-dropdown-menu .dropdown-item {
  color: #212529;
  font-size: 15px;
  line-height: 1.2;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.language-dropdown-menu .dropdown-item:hover {
  background-color: #e2e6ea !important;
  cursor: pointer;
  transform: translateX(3px);
}

.ai-challenge-language-dropdown {
  position: absolute;
  right: 0;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: white;
  box-shadow: 0px 2px 3px #212529;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  outline: none;
}

.ai-challenge-language-dropdown:hover,
.ai-challenge-language-dropdown:focus {
  box-shadow: 0px 2px 8px #212529;
}

.ai-challenge-language-dropdown option {
  padding: 5px;
  background: white;
  color: #333;
}

.ai-challenge-dropdown {
  appearance: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  border: 1px solid #ced4da;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
  background-color: #f8f9fa;
}

.ai-challenge-dropdown-ul {
  border: 1px solid #e3e6eb;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.generate-challenge-btn {
  /* background: linear-gradient(145deg, #FFD700, #DAA520);
  border: 2px solid #B8860B; */
  /* color: white; */
  /* background-color: #ffc107;
  color: #212529;
  border-color: #ffc107; */
  font-weight: bold;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 10px !important;
  /* box-shadow: 0 4px 10px rgba(255, 215, 0, 0.4); */
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.generate-challenge-btn:hover {
  /* background: linear-gradient(145deg, #DAA520, #FFD700); */
  /* box-shadow: 0 6px 15px rgba(255, 215, 0, 0.6); */
  transform: scale(1.05);
}

.generate-challenge-btn:active {
  transform: scale(0.98);
  /* box-shadow: 0 2px 5px rgba(255, 215, 0, 0.3); */
}

.generate-challenge-btn:disabled {
  /* transform: scale(0.98); */
  /* box-shadow: 0 2px 5px rgba(255, 215, 0, 0.1); */
  cursor: not-allowed;
}

.font-15 {
  font-size: 15px;
}

.monaco-editor-wrapper {
  border: 1px solid #ced4da !important;
  border-radius: 4px;
  overflow: hidden;
}

.ai-challenge-btn {
  position: relative;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 15px;
  background: linear-gradient(135deg, #5D5FEF, #9320D2);
  box-shadow: 0px 4px 15px rgba(93, 95, 239, 0.4);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.ai-challenge-btn:hover {
  background: linear-gradient(135deg, #764AF1, #B435E9);
  box-shadow: 0px 0px 20px rgba(118, 74, 241, 0.8);
}

.ai-challenge-btn:disabled {
  background: linear-gradient(135deg, #764AF1, #B435E9);
  /* box-shadow: none; */
  cursor: not-allowed;
  opacity: 0.8;
}

.ai-code-pre {
  white-space: pre-wrap;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
  padding-left: 15px;
}

.ai-code-heading {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 7px;
}

.ai-challenge-div{
  scrollbar-width: thin; /* Works in Firefox */
  scrollbar-color: rgba(100, 100, 100, 0.5) transparent;
}

/* Chrome, Edge, Safari */
.ai-challenge-div::-webkit-scrollbar {
  width: 8px; /* Thin scrollbar */
}

.ai-challenge-div::-webkit-scrollbar-track {
  background: transparent; /* Invisible track */
}

.ai-challenge-div::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #555, #999); /* Gradient thumb */
  border-radius: 4px; /* Rounded edges */
}

.ai-challenge-div::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #444, #888);
}

.height-85-vh {
  height: 85vh;
}

.ai-loader {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 5em;
}

.ai-loader li {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center center;
  animation: anim 1s ease-in-out infinite;
  will-change: transform, filter;
}

/* Future Dusk Color Palette */
:root {
  --dusk-purple: #5a496e;
  --dusk-blue: #4a5e88;
  --dusk-cyan: #5f8a8b;
  --dusk-orange: #de935f;
  --dusk-red: #a45a52;
}

.ai-loader li:nth-child(1) {
  width: 1em;
  height: 1em;
}
.ai-loader li:nth-child(1):after {
  content: "";
  display: block;
  font-size: 20%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  box-shadow: 1em 0 0 rgba(90, 73, 110, 0.2), -1em 0 0 rgba(74, 94, 136, 0.2),
    0 1em 0 rgba(95, 138, 139, 0.2), 0 -1em 0 rgba(222, 147, 95, 0.2);
}

.ai-loader li:nth-child(2) {
  animation-delay: 0.1s;
  width: 2em;
  height: 2em;
}
.ai-loader li:nth-child(2):after {
  content: "";
  display: block;
  font-size: 40%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  box-shadow: 1.5em 0 0 rgba(90, 73, 110, 0.4),
    -1.5em 0 0 rgba(74, 94, 136, 0.4), 0 1.5em 0 rgba(95, 138, 139, 0.4),
    0 -1.5em 0 rgba(222, 147, 95, 0.4);
}

.ai-loader li:nth-child(3) {
  animation-delay: 0.15s;
  width: 3em;
  height: 3em;
}
.ai-loader li:nth-child(3):after {
  content: "";
  display: block;
  font-size: 55%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  box-shadow: 2em 0 0 rgba(90, 73, 110, 0.6),
    -2em 0 0 rgba(74, 94, 136, 0.6), 0 2em 0 rgba(95, 138, 139, 0.6),
    0 -2em 0 rgba(222, 147, 95, 0.6);
}

.ai-loader li:nth-child(4) {
  animation-delay: 0.2s;
  width: 4em;
  height: 4em;
}
.ai-loader li:nth-child(4):after {
  content: "";
  display: block;
  font-size: 75%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  box-shadow: 2.5em 0 0 rgba(90, 73, 110, 0.8),
    -2.5em 0 0 rgba(74, 94, 136, 0.8), 0 2.5em 0 rgba(95, 138, 139, 0.8),
    0 -2.5em 0 rgba(222, 147, 95, 0.8);
}

.ai-loader li:nth-child(5) {
  animation-delay: 0.25s;
  width: 5em;
  height: 5em;
}
.ai-loader li:nth-child(5):after {
  content: "";
  display: block;
  font-size: 90%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  box-shadow: 3em 0 0 var(--dusk-purple), -3em 0 0 var(--dusk-blue), 
    0 3em 0 var(--dusk-cyan), 0 -3em 0 var(--dusk-orange);
}

@keyframes anim {
  50% {
    filter: blur(2px);
  }
  90%,
  100% {
    transform: rotate(1turn);
    filter: blur(0);
  }
}

.social-icons {
  display: flex;
  gap: 20px;
}
.social-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 24px;
  color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.social-btn:hover {
  transform: scale(1.1);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
}
.linkedin {
  background: #0077b5;
}
.instagram {
  background: linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888);
}
.youtube {
  background: #ff0000;
}

@media (max-width: 768px) {
  .drawer-icon img {
    width: 35px;
    height: 35px;
  }
  .tooltip {
    font-size: 10px;
  }
  .toggle-button {
    justify-content: left;
  }
}

/* Responsive Design */
@media (max-width: 1200px) {
  .visualization-canvas, .logger {
    height: auto;
  }
}

@media (max-width: 768px) {
  .content-area {
    flex-direction: column;
  }

  .pading-10 {
    padding-top: 10px;
  }

  .visualization-canvas {
    height: auto;
  }

  .logger-card {
    height: 200px;
  }

  .main-content {
    padding-left: 20px;
    padding-right: 20px
  }

  .learning-topics {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-header {
    font-size: 30px;
  }
}

/* Mobile Responsive */
@media (max-width: 576px) {
  .main-container {
    padding: 1rem;
  }

  .pading-10 {
    padding-top: 10px;
  }

  .visualization-canvas {
    height: auto;
  }

  .logger-card {
    height: 180px;
  }

  .section-title {
    font-size: 1.25rem;
  }

  .visualization-controls .form-range {
    width: 120px;
  }

  .main-content {
    padding-left: 20px;
    padding-right: 20px
  }

  .learning-topics {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-header {
    font-size: 30px;
  }
}

/* Responsive Design */
@media (max-width: 1200px) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

@media (max-width: 768px) {
  .logger-drawer {
    height: 20vh;
    bottom: -20vh;
  }
  .copy-btn {
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 50px;
    font-size: 10px;
  }
  .pre-div {
    display: grid;
    grid-template-columns: 35px 1fr;
    align-items: start;
  }
}

@media (max-width: 576px) {
  .logger-drawer {
    height: 10vh;
    bottom: -10vh;
  }
  .copy-btn {
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 50px;
    font-size: 10px;
  }
  .pre-div {
    display: grid;
    grid-template-columns: 25px 1fr;
    align-items: start;
  }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .content-area {
    flex-direction: row !important;
  }
  .content-area .col-lg-6 {
    flex: 1;
    max-width: 50%;
  }
  .pre-div {
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: start;
  }
  .copy-btn {
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 50px;
    font-size: 10px;
  }
}